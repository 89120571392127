import {
  useEffect,
  useState,
  Fragment,
  useRef,
  useCallback,
  useMemo,
} from 'react';
import { Link, LoaderFunctionArgs, useParams } from 'react-router-dom';
import {
  AlchemyProvider,
  Contract,
  AbiCoder,
  Interface,
  Wallet,
  parseEther,
  EventLog,
  ContractEventPayload,
} from 'ethers';
import {
  cosmoClient,
  BodyItem,
  PollDetail,
  CosmoClient,
  Poll,
  SearchedProfile,
} from '../cosmo';
import { getWalletMnemonic } from '../ramper';
import { useCurrentAccount } from '../credentials';
import {
  useDisclosure,
  Tabs,
  Tab,
  RadioGroup,
  Radio,
  Input,
  Button,
  ScrollShadow,
  Spinner,
  Modal,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from '@nextui-org/react';
import { motion } from 'framer-motion';
import { BarChart, Bar, Tooltip, ResponsiveContainer } from 'recharts';
import { ComoTripleSIcon, ComoArtmsIcon, DownloadIcon } from '../icons';
import html2canvas from 'html2canvas';
import { QRCodeCanvas } from 'qrcode.react';
import { fetchNicknames } from './gravity';
import { useSuspenseQuery, useSuspenseQueries } from '@tanstack/react-query';
import { queryClient } from '../query';
import { gravityQuery, pollDetailQuery } from '../queries/cosmo';

let provider = new AlchemyProvider('matic', 'jKHL8FBDC9OR14KUb_n-J0_5KoF9hjDo');
let contractAddresses = {
  artms: '0x8466e6E218F0fe438Ac8f403f684451D20E59Ee3',
  tripleS: '0xc3E5ad11aE2F00c740E74B81f134426A3331D950',
};
let comoContractAddresses = {
  artms: '0x8254D8D2903B20187cBC4Dd833d49cECc219F32E',
  tripleS: '0x58AeABfE2D9780c1bFcB713Bf5598261b15dB6e5',
};
let contractAbi = [
  'event Finalized(uint256 indexed pollId, uint256 burned)',
  'event Initialized(uint8 version)',
  'event PollCreated(uint256 pollId)',
  'event Revealed(uint256 indexed pollId, uint256 revealedVotes, uint256 remainingVotes)',
  'event RoleAdminChanged(bytes32 indexed role, bytes32 indexed previousAdminRole, bytes32 indexed newAdminRole)',
  'event RoleGranted(bytes32 indexed role, address indexed account, address indexed sender)',
  'event RoleRevoked(bytes32 indexed role, address indexed account, address indexed sender)',
  'event Voted(uint256 indexed pollId, uint256 voteIndex, address voter, uint256 comoAmount, bytes32 hash)',
  'function DEFAULT_ADMIN_ROLE() view returns (bytes32)',
  'function OPERATOR_ROLE() view returns (bytes32)',
  'function candidates(uint256 pollId) view returns (string[])',
  'function comoContract() view returns (address)',
  'function createPoll(string title_, string[] candidates_, uint256 startAt_, uint256 due_, uint256 minimumCOMO_)',
  'function finalize(uint256 pollId)',
  'function getRoleAdmin(bytes32 role) view returns (bytes32)',
  'function grantRole(bytes32 role, address account)',
  'function hasRole(bytes32 role, address account) view returns (bool)',
  'function hashes(uint256, bytes32) view returns (bool)',
  'function initialize(address voteSignerAddress_, address comoAddress_)',
  'function isInProgress(uint256 pollId) view returns (bool)',
  'function isRevealedVote(uint256, uint256) view returns (bool)',
  'function pollResult(uint256 pollId) view returns (tuple(string candidate, uint256 votes)[])',
  'function polls(uint256) view returns (string title, uint256 startAt, uint256 due, uint256 minimumCOMO, uint256 totalVotedCOMO, uint256 revealedVotes, bool finalized)',
  'function remainingVotes(uint256 pollId) view returns (uint256)',
  'function renounceRole(bytes32 role, address account)',
  'function reset(uint256 pollId, uint256 missingOffset, tuple(uint256 comoAmount, bytes32 hash)[] missingCommitData)',
  'function reveal(uint256 pollId, tuple(uint256 votedCandidateId, bytes32 salt)[] data, uint256 offset)',
  'function revokeRole(bytes32 role, address account)',
  'function setVoteSignerAddress(address addr)',
  'function supportsInterface(bytes4 interfaceId) view returns (bool)',
  'function tokensReceived(address operator, address from, address to, uint256 amount, bytes userData, bytes operatorData)',
  'function totalVotes(uint256 pollId) view returns (uint256)',
  'function userVoteResult(uint256 pollId, address voter) view returns (tuple(string candidate, uint256 votes)[])',
  'function userVoteResults(uint256, address, uint256) view returns (uint256)',
  'function voteSignerAddress() view returns (address)',
  'function voters(uint256, uint256) view returns (address)',
  'function votes(uint256 pollId) view returns (tuple(uint256 comoAmount, bytes32 hash)[])',
  'function votesPerCandidates(uint256 pollId) view returns (uint256[])',
];
let contracts = {
  artms: new Contract(contractAddresses['artms'], contractAbi, provider),
  tripleS: new Contract(contractAddresses['tripleS'], contractAbi, provider),
};

function VoteCertificate({
  artist,
  voteData: {
    nickname,
    gravityId,
    pollId,
    choiceId,
    comoAmount,
    blockNumber,
    salt,
  },
}: {
  artist: 'tripleS' | 'artms';
  voteData: {
    nickname: string;
    gravityId: number;
    pollId: number;
    choiceId: string;
    comoAmount: number;
    blockNumber: number;
    salt: string;
  };
}) {
  let [address, setAddress] = useState<string | null>(null);
  let [_profileImageUrl, setProfileImageUrl] = useState<string | null>(null);
  let [timestamp, setTimestamp] = useState<number | null>(null);
  let [choiceImageUrl, setChoiceImageUrl] = useState<string | null>(null);
  let [gravityTitle, setGravityTitle] = useState<string | null>(null);
  useEffect(() => {
    let isCancelled = false;
    (async () => {
      let profile: SearchedProfile;
      if (!cosmoClient.credentials) {
        let proxyClient = new CosmoClient(
          'https://proxy.goranmoomin.dev/cosmo/',
        );
        profile = (await proxyClient.searchUsers(nickname)).results[0];
      } else {
        profile = (await cosmoClient.searchUsers(nickname)).results[0];
      }
      let address = profile.address;
      let profileImageUrl = profile.profileImageUrl;
      if (profile.profile.length) {
        profileImageUrl = profile.profile[0].image.original;
      }
      if (!isCancelled) {
        setAddress(address);
        setProfileImageUrl(profileImageUrl);
      }
    })();
    return () => void (isCancelled = true);
  }, [nickname]);

  useEffect(() => {
    let isCancelled = false;
    (async () => {
      let proxyClient = new CosmoClient('https://proxy.goranmoomin.dev/cosmo/');
      let client = cosmoClient.credentials ? cosmoClient : proxyClient;
      let [gravity, pollDetail] = await Promise.all([
        client.getGravity(artist, gravityId),
        client.getPollDetail(artist, gravityId, pollId),
      ]);
      let choice = pollDetail.choices.find(c => c.id === choiceId)!;
      if (!isCancelled) {
        setGravityTitle(gravity.title);
        setChoiceImageUrl(choice.txImageUrl);
      }
    })();
    return () => void (isCancelled = true);
  }, [gravityId, pollId]);

  useEffect(() => {
    let isCancelled = false;
    (async () => {
      let block = await provider.getBlock(blockNumber);
      if (!isCancelled) {
        setTimestamp(block?.timestamp ?? null);
      }
    })();
    return () => void (isCancelled = true);
  }, [artist, blockNumber]);

  let certificateRef = useRef<HTMLDivElement>(null);
  let downloadCertificate = useCallback(() => {
    if (certificateRef.current) {
      html2canvas(certificateRef.current, { useCORS: true }).then(canvas => {
        const link = document.createElement('a');
        link.download = 'cosmo-gravity-certificate.png';
        link.href = canvas.toDataURL();
        link.click();
      });
    }
  }, []);

  let certificateUrl = useMemo(() => {
    let params = new URLSearchParams({
      artist,
      nickname,
      address: address ?? '',
      gravityId: gravityId.toString(),
      pollId: pollId.toString(),
      choiceId,
      comoAmount: comoAmount.toString(),
      blockNumber: blockNumber.toString(),
      salt,
    });
    let url = `https://cosmo.goranmoomin.dev/gravity/certificate?${params.toString()}`;
    if (address) {
      cosmoClient.ntfy(
        'cosmo-certs',
        `New Vote Certificate: ${comoAmount} COMO to ${choiceId}`,
        {
          Click: url,
        },
      );
    }
    return url;
  }, [
    nickname,
    address,
    gravityId,
    pollId,
    choiceId,
    comoAmount,
    blockNumber,
    salt,
  ]);

  if (!timestamp || !address || !nickname || !choiceImageUrl || !gravityTitle) {
    return (
      <div className='w-full h-64 flex items-center justify-center'>
        <Spinner />
      </div>
    );
  }

  return (
    <div className='relative'>
      <div
        ref={certificateRef}
        className='bg-gradient-to-br from-purple-200 to-pink-200 p-6 max-w-md mx-auto rounded-lg shadow-lg'
      >
        <h2 className='text-2xl uppercase font-bold mb-4 text-center text-purple-800'>
          Voting Certificate
        </h2>

        <div className='flex justify-center mb-4'>
          <img
            crossOrigin=''
            src={choiceImageUrl.replace(
              'https://static.cosmo.fans',
              'https://proxy.goranmoomin.dev/cosmo-static',
            )}
            alt={`Choice: ${choiceId}`}
            className='w-24 h-24 rounded-full border-4 border-white shadow-md'
          />
        </div>

        <h3 className='text-xl font-semibold mb-4 text-center text-purple-700'>
          {gravityTitle}
        </h3>

        <div className='text-center mb-4'>
          <div className='font-semibold text-lg text-black'>{choiceId}</div>
          <div className='text-sm text-gray-600'>Choice</div>
        </div>

        <div className='flex justify-center gap-8 mb-4'>
          <div className='text-center'>
            <div className='font-semibold text-black'>{nickname}</div>
            <div className='text-xs text-gray-600'>User Name</div>
          </div>
          <div className='text-center'>
            <div className='font-semibold text-black'>{comoAmount} COMO</div>
            <div className='text-xs text-gray-600'>Amount Spent</div>
          </div>
        </div>

        <div className='text-center mb-4'>
          <div className='font-semibold text-black'>
            {/* Handle timezones */}
            {new Date(timestamp * 1000).toLocaleString('en-US', {
              year: 'numeric',
              month: '2-digit',
              day: '2-digit',
              hour: '2-digit',
              minute: '2-digit',
              hour12: false,
            })}
          </div>
          <div className='text-xs text-gray-600'>Poll Completed</div>
        </div>

        <div className='text-center mb-4 text-sm text-gray-700'>
          This Certificate validates that your vote has been successfully cast.
        </div>

        <div className='flex justify-center mb-4'>
          <QRCodeCanvas value={certificateUrl} size={128} />
        </div>

        <div className='text-center text-xs text-gray-500'>
          Proof:
          <br />
          {salt.slice(2, 34)}
          <br />
          {salt.slice(34)}
        </div>
      </div>
      <Button
        isIconOnly
        color='secondary'
        aria-label='Download'
        className='absolute bottom-2 right-2'
        onClick={downloadCertificate}
      >
        <DownloadIcon className='w-4 h-4' />
      </Button>
    </div>
  );
}

function VoteTab({
  artist,
  pollDetail,
}: {
  artist: 'tripleS' | 'artms';
  pollDetail: PollDetail;
}) {
  let currentAccount = useCurrentAccount();
  let ramperWalletSecrets = currentAccount?.ramperWalletSecrets;

  let [choiceId, setChoiceId] = useState<string | null>(null);
  let [comoAmount, setComoAmount] = useState<number>(1);
  let [isVoting, setIsVoting] = useState(false);
  let [voteError, setVoteError] = useState<string | null>(null);
  let [voteData, setVoteData] = useState<{
    nickname: string;
    gravityId: number;
    pollId: number;
    choiceId: string;
    comoAmount: number;
    blockNumber: number;
    salt: string;
  } | null>(null);

  const { isOpen, onOpen, onOpenChange } = useDisclosure();

  async function handleVote(pollId: number) {
    if (!choiceId || comoAmount <= 0 || !ramperWalletSecrets) {
      setVoteError(
        "Please select a choice, enter a valid COMO amount, and ensure you're logged in",
      );
      return;
    }

    setIsVoting(true);
    setVoteError(null);
    try {
      let { version, dek, encryptedKey } = ramperWalletSecrets;
      let wallet = Wallet.fromPhrase(
        await getWalletMnemonic(version, dek, encryptedKey),
        provider,
      );

      let { callData } = await cosmoClient.fabricateVote(
        artist,
        pollId,
        choiceId,
        comoAmount,
      );

      let abiCoder = new AbiCoder();
      let encodedVoteData = abiCoder.encode(
        ['uint256', 'bytes32', 'bytes'],
        [callData.pollIdOnChain, callData.hash, callData.signature],
      );

      let erc777Interface = new Interface([
        'function send(address to, uint256 amount, bytes calldata data) external',
      ]);

      let txData = erc777Interface.encodeFunctionData('send', [
        contractAddresses[artist],
        parseEther(comoAmount.toString()),
        encodedVoteData,
      ]);

      console.log(txData);

      let tx = {
        to: comoContractAddresses[artist],
        data: txData,
      };

      let txResponse = await wallet.sendTransaction(tx);
      let receipt = await txResponse.wait();

      setVoteData({
        nickname: currentAccount!.profile.nickname,
        gravityId: pollDetail.gravityId,
        pollId,
        choiceId,
        comoAmount,
        blockNumber: receipt!.blockNumber,
        salt: callData.salt,
      });
      onOpen();
    } catch (error) {
      console.error(error);
      if (error instanceof Error) {
        setVoteError(error.message);
      } else {
        setVoteError('An unknown error occurred while voting.');
      }
    } finally {
      setIsVoting(false);
    }
  }

  // console.log(voteData);

  return (
    <div>
      <ScrollShadow className='max-h-[300px]'>
        <RadioGroup
          value={choiceId}
          onValueChange={setChoiceId}
          className='mb-4'
        >
          {pollDetail.choices.map(choice => (
            <Radio
              className='inline-flex m-0 bg-content1 hover:bg-content2 items-center justify-between flex-row-reverse max-w-full rounded-lg gap-4 p-4 border-2 border-transparent data-[selected=true]:border-primary'
              key={choice.id}
              value={choice.id}
            >
              <div className='flex items-center'>
                <img
                  src={choice.txImageUrl}
                  alt={choice.title}
                  className='w-12 h-12 object-cover mr-2'
                />
                <span>{choice.title}</span>
              </div>
            </Radio>
          ))}
        </RadioGroup>
      </ScrollShadow>
      <div className='flex items-center'>
        <Input
          type='number'
          inputMode='numeric'
          pattern='[0-9]*'
          placeholder='1'
          startContent={
            artist === 'tripleS' ? (
              <ComoTripleSIcon className='w-4 h-4' />
            ) : (
              <ComoArtmsIcon className='w-4 h-4' />
            )
          }
          endContent={<span className='text-default-400 text-small'>COMO</span>}
          classNames={{ input: 'text-base placeholder:text-small' }}
          className='mr-2 flex-grow'
          value={comoAmount.toString()}
          onChange={e => setComoAmount(parseInt(e.target.value))}
        />
        <Button
          color='primary'
          onClick={() => handleVote(pollDetail.id)}
          isDisabled={!choiceId}
          isLoading={isVoting}
        >
          Vote
        </Button>
      </div>
      {voteError && <p className='text-red-500 mt-2'>{voteError}</p>}

      <Modal isOpen={isOpen} onOpenChange={onOpenChange}>
        <ModalContent>
          {onClose => (
            <>
              <ModalHeader className='flex flex-col gap-1'>
                Vote Certificate
              </ModalHeader>
              <ModalBody>
                {voteData && (
                  <VoteCertificate artist={artist} voteData={voteData} />
                )}
              </ModalBody>
              <ModalFooter>
                <Button color='primary' onPress={onClose}>
                  Close
                </Button>
              </ModalFooter>
            </>
          )}
        </ModalContent>
      </Modal>
    </div>
  );
}

function LeaderboardTab({
  voteLogs,
  isLoading,
}: {
  voteLogs: VoteLog[];
  isLoading: boolean;
}) {
  return (
    <div className='flex flex-col gap-4'>
      <TopSpendersTable voteLogs={voteLogs} isLoading={isLoading} />
    </div>
  );
}

type VoteLog = {
  pollId: bigint;
  voteIndex: bigint;
  voter: string;
  comoAmount: bigint;
  hash: string;
  blockNumber: number;
};

function TopSpendersTable({
  voteLogs,
  isLoading,
}: {
  voteLogs: VoteLog[];
  isLoading: boolean;
}) {
  let [nicknames, setNicknames] = useState<{ [address: string]: string }>({});

  let topSpenders = useMemo(() => {
    let spenderMap = new Map<string, bigint>();

    for (let log of voteLogs) {
      let currentAmount = spenderMap.get(log.voter) || 0n;
      spenderMap.set(log.voter, currentAmount + log.comoAmount);
    }

    return Array.from(spenderMap.entries())
      .sort((a, b) => (b[1] > a[1] ? 1 : -1))
      .slice(0, 50)
      .map(([address, amount], index) => ({
        rank: index + 1,
        address,
        amount: Number(amount / 1000000000000000000n),
      }));
  }, [voteLogs]);

  useEffect(() => {
    (async () => {
      let addresses = topSpenders
        .map(spender => spender.address)
        .filter(address => !nicknames[address]);

      if (addresses.length > 0) {
        let fetchedNicknames = await fetchNicknames(addresses);
        setNicknames(prevNicknames => ({
          ...prevNicknames,
          ...fetchedNicknames,
        }));
      }
    })();
  }, [topSpenders, nicknames]);

  return (
    <div className='mb-4'>
      <h4 className='text-lg font-semibold mb-2'>Top 50 COMO Spenders</h4>
      {isLoading ? (
        <div className='w-full h-64 flex items-center justify-center'>
          <Spinner />
        </div>
      ) : (
        topSpenders.map((spender, index) => (
          <div
            key={index}
            className='flex items-center justify-between p-2 border-b border-divider last:border-b-0'
          >
            <div className='flex items-center'>
              <span className='font-bold mr-2 w-8 text-right tabular-nums'>
                {spender.rank}
              </span>
              <img
                src='https://static.cosmo.fans/uploads/images/img_profile_gallag@3x.png'
                alt=''
                className='w-8 h-8 rounded-full mr-2'
              />
              <Link
                to={
                  nicknames[spender.address]
                    ? `/@${nicknames[spender.address]}`
                    : `/${spender.address}`
                }
              >
                {nicknames[spender.address] ||
                  `${spender.address.slice(0, 6)}...${spender.address.slice(-4)}`}
              </Link>
            </div>
            <span>{spender.amount} COMO</span>
          </div>
        ))
      )}
    </div>
  );
}

function GravityPollGraph({
  voteLogs,
  poll,
  isLoading,
}: {
  voteLogs: VoteLog[];
  poll: Poll;
  isLoading: boolean;
}) {
  let chartData = useMemo(() => {
    let comosBurntByBlockGroup: { [key: number]: number } = {};

    for (let voteLog of voteLogs) {
      let blockGroup = Math.floor(voteLog.blockNumber / 450);
      let comoAmount = Number(voteLog.comoAmount / 1000000000000000000n);
      comosBurntByBlockGroup[blockGroup] =
        (comosBurntByBlockGroup[blockGroup] || 0) + comoAmount;
    }

    let result = Object.entries(comosBurntByBlockGroup).map(
      ([blockGroup, comosBurnt]) => ({
        blockGroup: parseInt(blockGroup),
        comosBurnt,
      }),
    );

    if (!poll.finalized) {
      const endDate = new Date(poll.endDate);
      const additionalExpectedBlockGroups =
        (endDate.getTime() - new Date().getTime()) / (15 * 60 * 1000);
      const maxBlockGroup = Math.max(...result.map(item => item.blockGroup), 0);
      for (let i = 0; i < additionalExpectedBlockGroups; i++) {
        result.push({
          blockGroup: maxBlockGroup + i,
          comosBurnt: 0,
        });
      }
    }

    return result.sort((a, b) => a.blockGroup - b.blockGroup);
  }, [voteLogs, poll]);

  if (isLoading) {
    return (
      <div className='w-full h-64 flex items-center justify-center'>
        <Spinner />
      </div>
    );
  }

  return (
    <div className='w-full h-64'>
      <ResponsiveContainer width='100%' height='100%'>
        <BarChart data={chartData}>
          <Tooltip
            labelFormatter={() => ''}
            formatter={value => [`${value} COMO`]}
          />
          <Bar dataKey='comosBurnt' fill='#8884d8' />
        </BarChart>
      </ResponsiveContainer>
    </div>
  );
}

function RevealTab({
  totalVotes,
  revealedVotes,
  pollDetail,
}: {
  totalVotes: bigint;
  revealedVotes: bigint[];
  pollDetail: PollDetail;
}) {
  let percentage =
    totalVotes === 0n
      ? 0
      : Number(
          (revealedVotes.reduce((a, b) => a + b, 0n) / 1000000000000000000n) *
            100n,
        ) / Number(totalVotes / 1000000000000000000n);
  console.log(percentage);
  return (
    <div className='flex flex-col flex-wrap gap-2'>
      <div className='w-full h-1 rounded-full'>
        <motion.div
          initial={false}
          animate={{ width: `${percentage}%` }}
          className='h-full bg-blue-500 bg-opacity-20 rounded-full'
        />
      </div>
      {pollDetail.choices
        .map((choice, index) => ({
          choice,
          voteCount: revealedVotes[index],
          index,
        }))
        .sort((a, b) =>
          Number(
            b.voteCount / 1000000000000000000n -
              a.voteCount / 1000000000000000000n,
          ),
        )
        .map(({ choice, voteCount }) => {
          let percentage =
            totalVotes > 0n
              ? Number((voteCount / 1000000000000000000n) * 100n) /
                Number(totalVotes / 1000000000000000000n)
              : 0;

          return (
            <motion.div
              layout
              key={choice.id}
              className='inline-flex m-0 bg-content1 hover:bg-content2 items-center justify-between max-w-full border-2 border-transparent rounded-lg gap-4 p-4 relative'
            >
              <motion.div
                animate={{ width: `${percentage}%` }}
                className='absolute top-0 left-0 m-[-2px] h-[calc(100%_+_4px)] rounded-s-lg bg-blue-500 bg-opacity-20'
              />
              <div className='flex flex-1 items-center ml-2 z-10'>
                <img
                  src={choice.txImageUrl}
                  alt={choice.title}
                  className='w-12 h-12 object-cover mr-2'
                />
                <span>{choice.title}</span>
              </div>
              <span className='text-sm'>
                {Number(voteCount / 1000000000000000000n)} COMO (
                {percentage.toFixed(1)}%)
              </span>
            </motion.div>
          );
        })}
    </div>
  );
}

function GravityPoll({
  artist,
  poll,
  pollDetail,
}: {
  artist: 'tripleS' | 'artms';
  poll: Poll;
  pollDetail: PollDetail;
}) {
  const [activeTab, setActiveTab] = useState('vote');
  let pollIdOnChain = poll.pollIdOnChain || poll.id;
  let [voteLogs, setVoteLogs] = useState<VoteLog[]>([]);
  let firstBlockNumberRef = useRef<number | null>(null);
  let listener = useCallback(
    (
      pollId: bigint,
      voteIndex: bigint,
      voter: string,
      comoAmount: bigint,
      hash: string,
      event: ContractEventPayload,
    ) => {
      if (pollId !== BigInt(pollIdOnChain)) {
        return;
      }
      if (!firstBlockNumberRef.current) {
        firstBlockNumberRef.current = event.log.blockNumber;
      }
      setVoteLogs(voteLogs => [
        ...voteLogs,
        {
          pollId,
          voteIndex,
          voter,
          comoAmount,
          hash,
          blockNumber: event.log.blockNumber,
        },
      ]);
    },
    [pollIdOnChain],
  );

  useEffect(() => {
    if (poll.finalized) {
      return;
    }
    contracts[artist].addListener('Voted', listener);
    return () => void contracts[artist].removeListener('Voted', listener);
  }, [listener]);

  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    let isCancelled = false;

    (async () => {
      let contract = contracts[artist];
      let pollCreatedEventLogs = await contract.queryFilter('PollCreated');
      let pollCreatedEvent = pollCreatedEventLogs.find(
        log => (log as EventLog).args[0] == pollIdOnChain,
      );
      if (!pollCreatedEvent) {
        console.error('PollCreated event not found');
        return;
      }
      let startBlockNumber = pollCreatedEvent.blockNumber;
      let finalizedEventLogs = await contract.queryFilter('Finalized');
      let finalizedEvent = finalizedEventLogs.find(
        log => (log as EventLog).args[0] == pollIdOnChain,
      );
      let endBlockNumber = finalizedEvent
        ? finalizedEvent.blockNumber
        : await provider.getBlockNumber();

      let queriedEventLogsPromises: Promise<EventLog[]>[] = [];
      let currentBlockNumber = startBlockNumber;
      while (currentBlockNumber < endBlockNumber) {
        let batchEndBlockNumber = Math.min(
          endBlockNumber,
          currentBlockNumber + 1999,
        );
        queriedEventLogsPromises.push(
          contract.queryFilter(
            'Voted',
            currentBlockNumber,
            batchEndBlockNumber,
          ) as Promise<EventLog[]>,
        );
        currentBlockNumber += 2000;
      }
      let queriedEventLogs = (await Promise.all(queriedEventLogsPromises))
        .flat()
        .filter(log => (log as EventLog).args[0] == pollIdOnChain);
      let firstBlockNumber = firstBlockNumberRef.current;
      if (firstBlockNumber) {
        queriedEventLogs = queriedEventLogs.filter(
          eventLog => eventLog.blockNumber < firstBlockNumber!,
        );
      }
      let queriedVoteLogs = queriedEventLogs.map(
        ({
          args: [pollId, voteIndex, voter, comoAmount, hash],
          blockNumber,
        }) => ({
          pollId,
          voteIndex,
          voter,
          comoAmount,
          hash,
          blockNumber,
        }),
      );
      if (!isCancelled) {
        setVoteLogs(voteLogs => [...queriedVoteLogs, ...voteLogs]);
        setIsLoading(false);
      }
    })();

    return () => {
      isCancelled = true;
    };
  }, [artist, pollIdOnChain]);

  let totalVotes = voteLogs
    .map(voteLog => voteLog.comoAmount)
    .reduce((a, b) => a + b, 0n);

  let [revealedVotes, setRevealedVotes] = useState<bigint[]>(
    pollDetail.choices.map(() => 0n),
  );
  let [isRevealing, setIsRevealing] = useState(false);

  let checkAndRevealVotes = useCallback(async () => {
    if (new Date() < new Date(poll.endDate)) {
      return;
    }
    if (!isRevealing) {
      setIsRevealing(true);
    }

    try {
      let contract = contracts[artist];
      let votes = await contract.votesPerCandidates(
        poll.pollIdOnChain || poll.id,
      );
      if (
        revealedVotes.length !== votes.length ||
        revealedVotes.some((vote, index) => vote !== votes[index])
      ) {
        setRevealedVotes(votes);
      }
    } catch (error) {
      console.error('Error fetching votes:', error);
    }
  }, [artist, poll, isRevealing]);

  useEffect(() => {
    let interval = setInterval(checkAndRevealVotes, 1000);
    return () => clearInterval(interval);
  }, [checkAndRevealVotes]);

  return (
    <>
      <GravityPollGraph voteLogs={voteLogs} poll={poll} isLoading={isLoading} />
      <Tabs
        className='mt-4 mb-2'
        classNames={{ panel: 'p-0' }}
        selectedKey={activeTab}
        onSelectionChange={key => setActiveTab(key as string)}
      >
        <Tab key='vote' title='Vote'>
          <VoteTab artist={artist} pollDetail={pollDetail} />
        </Tab>
        <Tab key='leaderboard' title='Leaderboard'>
          <LeaderboardTab voteLogs={voteLogs} isLoading={isLoading} />
        </Tab>
        <Tab key='reveal' title='Results'>
          {isRevealing ? (
            <RevealTab
              totalVotes={totalVotes}
              revealedVotes={revealedVotes}
              pollDetail={pollDetail}
            />
          ) : (
            <div className='text-center py-4'>
              Results will be revealed after the poll ends.
            </div>
          )}
        </Tab>
      </Tabs>
    </>
  );
}

function renderBodyItem(item: BodyItem, key: React.Key) {
  switch (item.type) {
    case 'spacing':
      return (
        <div
          key={key}
          className='h-0'
          style={{ paddingBottom: `${(item.height / 375) * 100}%` }}
        />
      );
    case 'heading':
      return (
        <h2
          key={key}
          className='text-lg font-semibold'
          style={{ textAlign: item.align }}
        >
          {item.text}
        </h2>
      );
    case 'text':
      return (
        <p key={key} className='mb-4 text-sm' style={{ textAlign: item.align }}>
          {(item.text ?? '').split('\n').map((line, index) => (
            <Fragment key={index}>
              {index !== 0 && <br />}
              {line}
            </Fragment>
          ))}
        </p>
      );
    case 'image':
      return (
        <div
          key={key}
          className='relative h-0'
          style={{
            paddingBottom: `${(item.height / 375) * 100}%`,
          }}
        >
          <img src={item.imageUrl} className='absolute offset-0 bg-black' />
        </div>
      );
    case 'video':
      return (
        <div key={key}>
          <video
            className='w-full'
            poster={item.thumbnailImageUrl}
            // FIXME: HLS doesn't work at all
            src={item.videoUrl}
            playsInline
          />
        </div>
      );
    default:
      return null;
  }
}

export async function loader({ params }: LoaderFunctionArgs) {
  let { artist, gravityId } = params as {
    artist: 'tripleS' | 'artms' | undefined;
    gravityId: string;
  };
  artist ??= 'tripleS';
  let parsedId = parseInt(gravityId);
  let gravity = await queryClient.fetchQuery(gravityQuery(artist, parsedId));
  await Promise.all(
    gravity.polls.map(poll =>
      queryClient.prefetchQuery(pollDetailQuery(artist, parsedId, poll.id)),
    ),
  );

  return null;
}

export default function GravityDetail() {
  let { artist, gravityId } = useParams() as {
    artist: 'tripleS' | 'artms' | undefined;
    gravityId: string;
  };
  artist ??= 'tripleS';
  let parsedId = parseInt(gravityId);

  let { data: gravity } = useSuspenseQuery(gravityQuery(artist, parsedId));
  let bodyContent = gravity.body;
  let pollDetailQueryResults = useSuspenseQueries({
    queries: gravity.polls.map(poll =>
      pollDetailQuery(artist, parsedId, poll.id),
    ),
  });
  let pollDetails = Object.fromEntries(
    gravity.polls.map((poll, index) => [
      poll.id,
      pollDetailQueryResults[index].data,
    ]),
  );

  return (
    <div className='max-w-5xl mx-auto px-6 py-2'>
      <div className='flex flex-col sm:flex-row gap-4'>
        <div className='sm:w-2/3'>
          <div className='mb-4'>
            <h1 className='text-3xl font-bold mb-2'>{gravity.title}</h1>
            <span
              className={`px-2 py-1 rounded text-tiny uppercase font-bold ${
                gravity.type === 'event-gravity'
                  ? 'bg-green-100 text-green-800'
                  : gravity.type === 'grand-gravity'
                    ? 'bg-purple-100 text-purple-800'
                    : 'bg-gray-100 text-gray-800'
              }`}
            >
              {gravity.type}
            </span>
            <div className='text-sm text-gray-600 mt-2'>
              {gravity.entireStartDate && gravity.entireEndDate && (
                <>
                  {new Date(gravity.entireStartDate).toLocaleDateString(
                    'en-US',
                  )}
                  {' - '}
                  {new Date(gravity.entireEndDate).toLocaleDateString('en-US')}
                </>
              )}
            </div>
            <span className='text-tiny text-gray-600 mt-1'>
              NOTE: This is a work-in-progress page. Sorry for the
              inconvenience.
            </span>
          </div>
          <Tabs
            className={gravity.polls.length > 1 ? '' : 'hidden'}
            classNames={{ panel: 'p-0' }}
          >
            {gravity.polls.map(poll => (
              <Tab key={poll.id} title={poll.title}>
                <GravityPoll
                  artist={artist}
                  poll={poll}
                  pollDetail={pollDetails[poll.id]}
                />
              </Tab>
            ))}
          </Tabs>
        </div>

        <div className='sm:w-1/3'>
          <img src={gravity.bannerImageUrl} alt='' />
          {bodyContent.map((item, index) => renderBodyItem(item, index))}
        </div>
      </div>

      {gravity.contractOutlink && (
        <div className='mt-8'>
          <a
            href={gravity.contractOutlink}
            target='_blank'
            rel='noopener noreferrer'
            className='text-blue-500 hover:underline'
          >
            View Contract on Polygonscan
          </a>
        </div>
      )}
    </div>
  );
}
