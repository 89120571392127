import { useState } from 'react';
import {
  Card,
  CardHeader,
  CardFooter,
  Image,
  SelectItem,
  Select,
  Selection,
} from '@nextui-org/react';
import { Link } from 'react-router-dom';
import { useSuspenseQuery } from '@tanstack/react-query';

import { type Gravity } from '../cosmo';
import { gravitiesQuery } from '../queries/cosmo';
import { queryClient } from '../query';

export async function loader() {
  await Promise.all([
    queryClient.prefetchQuery(gravitiesQuery('tripleS')),
    queryClient.prefetchQuery(gravitiesQuery('artms')),
  ]);
  return null;
}

export default function Gravity() {
  let [artist, setArtist] = useState<'tripleS' | 'artms'>('tripleS');
  let { data: gravities } = useSuspenseQuery(gravitiesQuery(artist));

  function onArtistChange(artists: Selection) {
    setArtist(Array.from(artists as Set<'tripleS' | 'artms'>)[0]);
  }

  return (
    <div className='max-w-5xl mx-auto px-6 py-2'>
      <div className='flex flex-row items-center gap-4 mb-4'>
        <h1 className='flex-grow text-2xl font-bold'>Gravities</h1>
        <Select
          label='Artist'
          placeholder='Select an artist'
          selectedKeys={[artist]}
          onSelectionChange={onArtistChange}
          className='max-w-xs'
        >
          <SelectItem key='tripleS' value='tripleS'>
            tripleS
          </SelectItem>
          <SelectItem key='artms' value='artms'>
            artms
          </SelectItem>
        </Select>
      </div>
      <div className='grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4'>
        {[...gravities.upcoming, ...gravities.ongoing, ...gravities.past].map(
          gravity => (
            <GravityCard key={gravity.id} artist={artist} gravity={gravity} />
          ),
        )}
      </div>
    </div>
  );
}

function GravityCard({
  artist,
  gravity,
}: {
  artist: 'tripleS' | 'artms';
  gravity: Gravity;
}) {
  const getStatus = (gravity: Gravity) => {
    const now = new Date().getTime();
    if (now < new Date(gravity.entireStartDate).getTime()) {
      return 'Upcoming';
    } else if (now > new Date(gravity.entireEndDate).getTime()) {
      return 'Past';
    } else {
      return 'Ongoing';
    }
  };

  const status = getStatus(gravity);

  return (
    <Link
      to={`/gravity/${artist}/${gravity.id}`}
      className='w-full aspect-w-1 aspect-h-1'
    >
      <Card shadow='none' className='absolute inset-0'>
        <CardHeader className='absolute z-10 top-1 flex-col items-start'>
          <p className='text-tiny text-white/60 uppercase font-bold'>
            {gravity.type}
          </p>
          <h4 className='text-white font-medium text-large'>{gravity.title}</h4>
        </CardHeader>
        <div className='relative z-0 w-full h-full group'>
          <Image
            removeWrapper
            alt='Gravity banner'
            className='absolute inset-0 z-0 group-hover:scale-110 object-cover'
            src={gravity.bannerImageUrl}
          />
          <div className='absolute inset-0 bg-gradient-to-b from-black/60 via-transparent to-black/60'></div>
        </div>
        <CardFooter className='absolute bottom-0 z-10'>
          <div className='flex flex-grow gap-2 items-center'>
            <div className='flex flex-col'>
              <span className='px-3 py-1 rounded-full text-xs font-semibold text-black bg-gray-200'>
                {status}
              </span>
            </div>
          </div>
        </CardFooter>
      </Card>
    </Link>
  );
}

export async function fetchNicknames(
  addresses: string[],
): Promise<{ [address: string]: string }> {
  let addressGroups = [];
  for (let i = 0; i < addresses.length; i += 150) {
    addressGroups.push(addresses.slice(i, i + 150));
  }

  async function fetchGroupNicknames(group: string[]) {
    let response = await fetch(
      `https://search.goranmoomin.dev/user/v1/by-address/${group.join(',')}`,
    );
    let users = (await response.json()) as {
      nickname: string;
      address: string;
    }[];
    return Object.fromEntries(
      users.map(({ nickname, address }) => [address, nickname]),
    );
  }

  try {
    return (await Promise.all(addressGroups.map(fetchGroupNicknames))).reduce(
      (acc, groupNicknames) => ({ ...acc, ...groupNicknames }),
      {},
    );
  } catch (error) {
    console.error('Failed to fetch nicknames:', error);
    return {};
  }
}
