import { useSearchParams } from 'react-router-dom';
import { useState, useEffect, useRef, useMemo } from 'react';
import {
  keccak256,
  AbiCoder,
  AlchemyProvider,
  Contract,
  EventLog,
} from 'ethers';
import { cosmoClient, CosmoClient, Gravity, PollDetail } from '../cosmo';
import { Spinner, Button } from '@nextui-org/react';
import { QRCodeCanvas } from 'qrcode.react';
import html2canvas from 'html2canvas';
import { DownloadIcon } from '../icons';

let contractAddress = '0xc3E5ad11aE2F00c740E74B81f134426A3331D950';
let contractAbi = [
  'event Voted(uint256 indexed pollId, uint256 voteIndex, address voter, uint256 comoAmount, bytes32 hash)',
];

let provider = new AlchemyProvider('matic', 'jKHL8FBDC9OR14KUb_n-J0_5KoF9hjDo');
let contract = new Contract(contractAddress, contractAbi, provider);

export default function GravityCertificate() {
  let [searchParams] = useSearchParams();
  let artist =
    (searchParams.get('artist') as 'tripleS' | 'artms' | null) ?? 'tripleS';
  let nickname = searchParams.get('nickname') ?? '';
  let address = searchParams.get('address') ?? '';
  let gravityId = parseInt(searchParams.get('gravityId') || '');
  let pollId = parseInt(searchParams.get('pollId') || '');
  let choiceId = searchParams.get('choiceId') ?? '';
  let comoAmount = parseInt(searchParams.get('comoAmount') || '');
  let blockNumber = parseInt(searchParams.get('blockNumber') || '');
  let salt = searchParams.get('salt') ?? '';

  let [isValid, setIsValid] = useState<boolean | null>(null);
  let [validationMessage, setValidationMessage] = useState<string>('');
  let [polygonscanUrl, setPolygonscanUrl] = useState<string | null>(null);
  let [_profileImageUrl, setProfileImageUrl] = useState<string | null>(null);
  let [timestamp, setTimestamp] = useState<number | null>(null);
  let [choiceImageUrl, setChoiceImageUrl] = useState<string | null>(null);
  let [gravityTitle, setGravityTitle] = useState<string | null>(null);

  let certificateRef = useRef<HTMLDivElement>(null);
  let certificateUrl = useMemo(() => {
    let params = new URLSearchParams({
      artist,
      nickname,
      address,
      gravityId: gravityId.toString(),
      pollId: pollId.toString(),
      choiceId,
      comoAmount: comoAmount.toString(),
      blockNumber: blockNumber.toString(),
      salt,
    });
    let url = `https://cosmo.goranmoomin.dev/gravity/certificate?${params.toString()}`;
    return url;
  }, [
    artist,
    nickname,
    address,
    gravityId,
    pollId,
    choiceId,
    comoAmount,
    blockNumber,
    salt,
  ]);

  useEffect(() => {
    let isCancelled = false;
    (async () => {
      try {
        let gravity: Gravity;
        let pollDetail: PollDetail;
        if (!cosmoClient.credentials) {
          let proxyClient = new CosmoClient(
            'https://proxy.goranmoomin.dev/cosmo/',
          );
          gravity = await proxyClient.getGravity(artist, gravityId);
          pollDetail = await proxyClient.getPollDetail(
            artist,
            gravityId,
            pollId,
          );
        } else {
          gravity = await cosmoClient.getGravity(artist, gravityId);
          pollDetail = await cosmoClient.getPollDetail(
            artist,
            gravityId,
            pollId,
          );
        }
        let poll = gravity.polls.find(poll => poll.id === pollId);
        if (!poll) {
          throw new Error('Poll not found');
        }
        let pollIdOnChain = poll.pollIdOnChain || poll.id;

        // Validate nickname resolves to address
        let profile = await cosmoClient.getProfileByNickname(nickname!);
        if (profile.address.toLowerCase() !== address!.toLowerCase()) {
          throw new Error('Nickname does not match address');
        }

        // Calculate hash
        let abiCoder = new AbiCoder();
        let choiceIndex = pollDetail.choices.findIndex(
          choice => choice.id === choiceId,
        );
        let calculatedHash = keccak256(
          abiCoder.encode(['uint256', 'bytes32'], [choiceIndex, salt]),
        );

        // Fetch event logs
        let filter = contract.filters.Voted(pollIdOnChain);
        let logs = await contract.queryFilter(filter, blockNumber, blockNumber);

        // Find matching log
        // event Voted(uint256 indexed pollId, uint256 voteIndex, address voter, uint256 comoAmount, bytes32 hash)
        let matchingLog = logs.find(
          log =>
            (log as EventLog).args[0] === BigInt(pollIdOnChain) &&
            (log as EventLog).args[2].toLowerCase() ===
              address!.toLowerCase() &&
            (log as EventLog).args[3] ===
              BigInt(comoAmount) * 1000000000000000000n &&
            (log as EventLog).args[4] === calculatedHash,
        );

        if (!isCancelled) {
          if (matchingLog) {
            setIsValid(true);
            setValidationMessage('Certificate is valid!');
            setPolygonscanUrl(
              `https://polygonscan.com/tx/${matchingLog.transactionHash}`,
            );
          } else {
            setIsValid(false);
            setValidationMessage(
              'Certificate is invalid. No matching vote found on the blockchain.',
            );
            setPolygonscanUrl(null);
          }
        }
        (async () => {
          try {
            let profile = await cosmoClient.getProfileByNickname(nickname!);
            setProfileImageUrl(profile.profileImageUrl);

            let gravity: Gravity;
            let pollDetail: PollDetail;
            if (!cosmoClient.credentials) {
              let proxyClient = new CosmoClient(
                'https://proxy.goranmoomin.dev/cosmo/',
              );
              gravity = await proxyClient.getGravity(artist, gravityId);
              pollDetail = await proxyClient.getPollDetail(
                artist,
                gravityId,
                pollId,
              );
            } else {
              gravity = await cosmoClient.getGravity(artist, gravityId);
              pollDetail = await cosmoClient.getPollDetail(
                artist,
                gravityId,
                pollId,
              );
            }

            setGravityTitle(gravity.title);
            const choice = pollDetail.choices.find(c => c.id === choiceId);
            if (choice) {
              setChoiceImageUrl(choice.txImageUrl);
            }

            const block = await provider.getBlock(blockNumber);
            if (block) {
              setTimestamp(block.timestamp);
            }
          } catch (error) {
            console.error('Error fetching additional data:', error);
          }
        })();
      } catch (error) {
        if (!isCancelled) {
          setIsValid(false);
          setValidationMessage(
            `Validation failed: ${error instanceof Error ? error.message : 'Unknown error'}`,
          );
          setPolygonscanUrl(null);
        }
      }
      return () => void (isCancelled = true);
    })();
  }, [artist, nickname, address, gravityId, pollId, choiceId, blockNumber]);

  const downloadCertificate = () => {
    if (certificateRef.current) {
      html2canvas(certificateRef.current, { useCORS: true }).then(canvas => {
        const link = document.createElement('a');
        link.download = 'cosmo-gravity-certificate.png';
        link.href = canvas.toDataURL();
        link.click();
      });
    }
  };

  if (isValid === null || !timestamp || !choiceImageUrl || !gravityTitle) {
    return (
      <div className='w-full h-64 flex items-center justify-center'>
        <Spinner />
      </div>
    );
  }

  return (
    <div className='relative max-w-md mx-auto px-6 py-2'>
      <div
        className={`p-4 mb-4 rounded-lg ${
          isValid ? 'bg-green-100' : 'bg-red-100'
        }`}
      >
        <p
          className={`font-semibold ${
            isValid ? 'text-green-700' : 'text-red-700'
          }`}
        >
          {validationMessage}
        </p>
      </div>

      <div className='relative'>
        <div
          ref={certificateRef}
          className={`bg-gradient-to-br from-purple-200 to-pink-200 p-6 rounded-lg shadow-lg ${
            !isValid ? 'opacity-50' : ''
          }`}
        >
          {!isValid && (
            <div className='absolute inset-0 flex items-center justify-center'>
              <div className='transform rotate-45 text-red-600 text-6xl font-bold border-8 border-red-600 p-4'>
                INVALID
              </div>
            </div>
          )}

          <h2 className='text-2xl uppercase font-bold mb-4 text-center text-purple-800'>
            Voting Certificate
          </h2>

          <div className='flex justify-center mb-4'>
            <img
              crossOrigin=''
              src={choiceImageUrl.replace(
                'https://static.cosmo.fans',
                'https://proxy.goranmoomin.dev/cosmo-static',
              )}
              alt={`Choice: ${choiceId}`}
              className='w-24 h-24 rounded-full border-4 border-white shadow-md'
            />
          </div>

          <h3 className='text-xl font-semibold mb-4 text-center text-purple-700'>
            {gravityTitle}
          </h3>

          <div className='text-center mb-4'>
            <div className='font-semibold text-lg text-black'>{choiceId}</div>
            <div className='text-sm text-gray-600'>Choice</div>
          </div>

          <div className='flex justify-center gap-8 mb-4'>
            <div className='text-center'>
              <div className='font-semibold text-black'>{nickname}</div>
              <div className='text-xs text-gray-600'>User Name</div>
            </div>
            <div className='text-center'>
              <div className='font-semibold text-black'>{comoAmount} COMO</div>
              <div className='text-xs text-gray-600'>Amount Spent</div>
            </div>
          </div>

          <div className='text-center mb-4'>
            <div className='font-semibold text-black'>
              {new Date(timestamp * 1000).toLocaleString('en-US', {
                year: 'numeric',
                month: '2-digit',
                day: '2-digit',
                hour: '2-digit',
                minute: '2-digit',
                hour12: false,
              })}
            </div>
            <div className='text-xs text-gray-600'>Poll Completed</div>
          </div>

          <div className='text-center mb-4 text-sm text-gray-700'>
            This Certificate validates that your vote has been successfully
            cast.
          </div>

          <div className='flex justify-center mb-4'>
            <QRCodeCanvas value={certificateUrl} size={128} />
          </div>

          <div className='text-center text-xs text-gray-500'>
            Proof:
            <br />
            {salt!.slice(2, 34)}
            <br />
            {salt!.slice(34)}
          </div>
        </div>

        {isValid && (
          <Button
            isIconOnly
            color='secondary'
            aria-label='Download'
            className='absolute bottom-2 right-2'
            onClick={downloadCertificate}
          >
            <DownloadIcon className='w-4 h-4' />
          </Button>
        )}
      </div>

      {polygonscanUrl && (
        <div className='mt-4 text-center'>
          <a
            href={polygonscanUrl}
            target='_blank'
            rel='noopener noreferrer'
            className='text-blue-500 hover:underline'
          >
            View Transaction on Polygonscan
          </a>
        </div>
      )}
    </div>
  );
}
