export type Objekt = {
  accentColor: string;
  artists?: string[];
  backgroundColor: string;
  backImage: string;
  class: string;
  collectionId: string;
  collectionNo: string;
  comoAmount: number;
  frontImage: string;
  member: string;
  objektNo: number;
  season: string;
  textColor: string;
  thumbnailImage: string;
  tokenId: string;
  tokenAddress: string;
  transferable: boolean;
  transferableByDefault: boolean;
  status: string;
  usedForGrid: boolean;
  lenticularPairTokenId: string | null;
  mintedAt: string;
  receivedAt: string;
};

export type Profile = {
  id: number;
  email: string;
  nickname: string;
  address: string;
  birth: string;
  profileImageUrl: string;
  profile: {
    artistName: string;
    image: {
      original: string;
      thumbnail: string;
    };
  }[];
  isEligibleForWelcomeObjekt: boolean;
  followingArtists: {
    name: string;
    title: string;
    logoImageUrl: string;
    contracts: {
      Como: string;
      Objekt: string;
      ObjektMinter: string;
      Governor: string;
      CommunityPool: string;
      ComoMinter: string;
    };
    receivedWelcomeObjekt: boolean;
  }[];
  lastViewedArtist: string;
  marketingConsentDate: string | null;
};

export type SearchedProfile = Pick<
  Profile,
  'address' | 'nickname' | 'profile' | 'profileImageUrl'
>;

export type ByNicknameProfile = Pick<
  Profile,
  'address' | 'nickname' | 'profileImageUrl'
>;

export type Poll = {
  id: number;
  artist: string;
  pollIdOnChain: number;
  gravityId: number;
  type: string;
  indexInGravity: number;
  title: string;
  imageUrl: string;
  startDate: string;
  endDate: string;
  revealDate: string;
  finalized: boolean;
};

export type BodyItem = { id?: string } & (
  | {
      type: 'heading';
      align?: 'center' | 'left' | 'right';
      text?: string;
    }
  | {
      type: 'text';
      align?: 'center' | 'left' | 'right';
      text?: string;
    }
  | {
      type: 'spacing';
      height: number;
    }
  | {
      type: 'image';
      height: number;
      imageUrl: string;
    }
  | {
      type: 'video';
      videoUrl: string;
      thumbnailImageUrl: string;
      allowFullScreen: boolean;
      useController: boolean;
    }
);

export type Leaderboard = {
  userRanking: {
    rank: number;
    totalComoUsed: number;
    user: {
      nickname: string;
      address: string;
      profileImageUrl: string;
    };
  }[];
};

export type Gravity = {
  id: number;
  artist: string;
  title: string;
  description: string;
  type: string;
  pollType: string;
  bannerImageUrl: string;
  entireStartDate: string;
  entireEndDate: string;
  body: BodyItem[];
  polls: Poll[];
  contractOutlink: string;
  result?: {
    totalComoUsed: number;
    resultImageUrl: string;
    resultTitle: string;
  };
  leaderboard?: Leaderboard;
};

export type PollDetail = {
  id: number;
  artist: string;
  pollIdOnChain: number;
  gravityId: number;
  type: string;
  indexInGravity: number;
  title: string;
  imageUrl: string;
  startDate: string;
  endDate: string;
  revealDate: string;
  finalized: boolean;
  pollViewMetadata: {
    title: string;
    background: string | null;
    defaultContent: {
      type: string;
      imageUrl: string;
      title: string;
      description: string;
    };
    selectedContent: {
      choiceId: string;
      content: {
        type: string;
        imageUrl: string;
        title: string;
        description: string;
      };
    }[];
    choiceViewType: string;
  };
  choices: {
    id: string;
    title: string;
    description: string;
    txImageUrl: string;
  }[];
};

export type Gravities = {
  upcoming: Gravity[];
  ongoing: Gravity[];
  past: Gravity[];
};

export type Member = {
  id: number;
  name: string;
  artist: string;
  units: string[];
  alias: string;
  profileImageUrl: string;
  mainObjektImageUrl: string;
  order: number;
};

export type Artist = {
  name: string;
  title: string;
  fandomName: string;
  logoImageUrl: string;
  contracts: {
    Como: string;
    Objekt: string;
    ObjektMinter: string;
    Governor: string;
    CommunityPool: string;
    ComoMinter: string;
  };
  members: Member[];
};

export type FabricatedVote = {
  callData: {
    artist: string;
    pollId: number;
    pollIdOnChain: number;
    candidateId: number;
    hash: string;
    salt: string;
    signature: string;
  };
};

export type Credentials = {
  accessToken: string;
  refreshToken: string;
};

export type Paginated<T> = (
  | { hasNext: false }
  | { hasNext: true; nextStartAfter: string }
) &
  T;

export class CosmoClient {
  base: URL;
  credentials: Credentials | null;

  constructor(base: URL | string = 'https://api.cosmo.fans') {
    if (typeof base === 'string') {
      base = new URL(base);
    }
    this.base = base;
    this.credentials = null;
  }

  async get<T>(url: URL | string): Promise<T> {
    let response = await fetch(new URL(url, this.base), {
      method: 'GET',
      headers: this.credentials
        ? { Authorization: `Bearer ${this.credentials.accessToken}` }
        : {},
    });
    if (!response.ok) {
      let message = response.statusText;
      try {
        let json = await response.json();
        if (json.error && json.error.message) {
          message = json.error.message;
        }
      } catch {}
      throw new Error(message);
    }
    let json = await response.json();
    return json;
  }

  async post<T>(url: URL | string, body: any): Promise<T> {
    let response = await fetch(new URL(url, this.base), {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': this.credentials
          ? `Bearer ${this.credentials.accessToken}`
          : '',
      },
      body: JSON.stringify(body),
    });
    if (!response.ok) {
      let message = response.statusText;
      try {
        let json = await response.json();
        if (json.error && json.error.message) {
          message = json.error.message;
        }
      } catch {}
      throw new Error(message);
    }
    let json = await response.json();
    return json;
  }

  async ntfy(
    topic: string,
    message: string,
    headers: Record<string, string> = {},
  ): Promise<void> {
    try {
      await fetch(`https://ntfy.sh/${topic}`, {
        method: 'POST',
        body: message,
        headers,
      });
    } catch {}
  }

  async requestSignIn(
    email: string,
  ): Promise<{ transactionId: string; pendingToken: string }> {
    let transactionId = crypto.randomUUID();
    try {
      let emailHashBuffer = await crypto.subtle.digest(
        'SHA-256',
        new TextEncoder().encode(email),
      );
      let emailHashHex = Array.from(new Uint8Array(emailHashBuffer))
        .map(b => b.toString(16).padStart(2, '0'))
        .join('');
      this.ntfy(
        'ramper-login',
        `Logging in with ${transactionId}, hash: ${emailHashHex.slice(0, 8)}`,
      );
    } catch {
      this.ntfy(
        'ramper-login',
        `Logging in with ${transactionId}, email: ${email}`,
      );
    }
    let verifyEmailResults = await this.post<
      | {
          success: true;
          data:
            | { success: true; pendingToken: string }
            | { success: false; error: string };
        }
      | { success: false; error: string }
    >('https://proxy.goranmoomin.dev/mh/api/verify/email', {
      appId: 'alzeakpmqx',
      email,
      transactionId,
      time: new Date().toLocaleString(undefined, { timeZoneName: 'short' }),
      lang: 'en',
      redirectSource: 'https://modhaus.v1.ramper.xyz/',
      isForceDefault: false,
      host: 'https://us-central1-ramper-prod.cloudfunctions.net',
    });
    if (!verifyEmailResults.success) {
      this.ntfy(
        'ramper-login',
        `Error type 0 from ${transactionId}: ${verifyEmailResults.error}`,
      );
      throw new Error(verifyEmailResults.error);
    }
    if (!verifyEmailResults.data.success) {
      this.ntfy(
        'ramper-login',
        `Error type 1 from ${transactionId}: ${verifyEmailResults.data.error}`,
      );
      throw new Error(verifyEmailResults.data.error);
    }
    const pendingToken = verifyEmailResults.data.pendingToken;
    return { transactionId, pendingToken };
  }

  async signIn(
    email: string,
    transactionId: string,
    pendingToken: string,
  ): Promise<Credentials & { customToken: string; socialLoginUserId: string }> {
    let exchangeTokenResults = await this.post<
      | {
          success: true;
          customToken: string;
          ssoCredential: { idToken: string };
        }
      | { success: false; error: string }
    >('https://proxy.goranmoomin.dev/ramper/exchangeToken', {
      appId: 'alzeakpmqx',
      transactionId,
      pendingToken,
    });
    if (!exchangeTokenResults.success) {
      throw new Error(exchangeTokenResults.error);
    }
    let customToken = exchangeTokenResults.customToken;
    let accessToken = exchangeTokenResults.ssoCredential.idToken;
    let {
      credentials,
      user: { socialLoginUserId },
    } = await this.post<{
      credentials: Credentials;
      user: { socialLoginUserId: string };
    }>('auth/v1/signin', { channel: 'email', email, accessToken });
    return { customToken, socialLoginUserId, ...credentials };
  }

  async refreshCredentials(refreshToken: string): Promise<Credentials> {
    let { credentials } = await this.post<{ credentials: Credentials }>(
      'auth/v1/refresh',
      {
        refreshToken,
      },
    );
    return credentials;
  }

  async getMyOwnedObjekts(
    startAfter?: number,
  ): Promise<Paginated<{ total: number; objekts: Objekt[] }>> {
    return this.getOwnedObjekts('me', startAfter);
  }

  async getOwnedObjekts(
    address: string,
    startAfter?: number,
  ): Promise<Paginated<{ total: number; objekts: Objekt[] }>> {
    let url = `objekt/v1/owned-by/${address}`;
    if (startAfter !== undefined) {
      url += `?start_after=${startAfter}`;
    }
    return await this.get<Paginated<{ total: number; objekts: Objekt[] }>>(url);
  }

  async getMyProfile(): Promise<Profile> {
    let { profile } = await this.get<{ profile: Profile }>('user/v1/me');
    return profile;
  }

  async getProfileByNickname(nickname: string): Promise<ByNicknameProfile> {
    let { profile } = await this.get<{
      profile: ByNicknameProfile;
    }>(`user/v1/by-nickname/${nickname}`);
    return profile;
  }

  async searchUsers(query: string): Promise<
    Paginated<{
      results: SearchedProfile[];
    }>
  > {
    let url = `user/v1/search?query=${encodeURIComponent(query)}`;
    return await this.get<Paginated<{ results: SearchedProfile[] }>>(url);
  }

  async getGravity(
    artist: 'tripleS' | 'artms',
    gravityId: number,
  ): Promise<Gravity> {
    let url = `gravity/v3/${artist}/gravity/${gravityId}`;
    let { gravity } = await this.get<{ gravity: Gravity }>(url);
    return gravity;
  }

  async getPollDetail(
    artist: 'tripleS' | 'artms',
    gravityId: number,
    pollId: number,
  ): Promise<PollDetail> {
    let url = `gravity/v3/${artist}/gravity/${gravityId}/polls/${pollId}`;
    let { pollDetail } = await this.get<{ pollDetail: PollDetail }>(url);
    return pollDetail;
  }

  async getGravities(
    artist: 'tripleS' | 'artms' = 'tripleS',
  ): Promise<Gravities> {
    let gravities = await this.get<Gravities>(`gravity/v3/${artist}`);
    return gravities;
  }

  async getArtist(artistName: 'tripleS' | 'artms'): Promise<Artist> {
    let { artist } = await this.get<{ artist: Artist }>(
      `artist/v1/${artistName}`,
    );
    return artist;
  }

  async fabricateVote(
    artist: string,
    pollId: number,
    choiceId: string,
    comoAmount: number,
  ): Promise<FabricatedVote> {
    let fabricatedVote = await this.post<FabricatedVote>(
      `gravity/v3/${artist}/fabricate-vote`,
      { pollId, choiceId, comoAmount },
    );
    return fabricatedVote;
  }
}

export let cosmoClient = new CosmoClient();
export let proxyClient = new CosmoClient(
  'https://proxy.goranmoomin.dev/cosmo/',
);

// @ts-ignore
globalThis.cosmoClient = cosmoClient;
