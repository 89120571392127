import { FetchQueryOptions } from '@tanstack/react-query';
import { List, polarisClient, Transfer } from '../polaris';
import {
  Artist,
  Objekt,
  Paginated,
  SearchedProfile,
  cosmoClient,
  proxyClient,
  Gravities,
  Gravity,
  PollDetail,
} from '../cosmo';
import { queryClient } from '../query';

export const allObjektsQuery: FetchQueryOptions<Objekt[]> = {
  queryKey: ['allObjekts'],
  queryFn: () => polarisClient.getAllObjekts(),
  staleTime: Infinity,
};

export function artistQuery(
  name: 'tripleS' | 'artms',
): FetchQueryOptions<Artist> {
  return {
    queryKey: ['artist', name],
    queryFn: () => cosmoClient.getArtist(name),
    staleTime: Infinity,
  };
}

export function searchUsersQuery(
  nickname: string,
): FetchQueryOptions<Paginated<{ results: SearchedProfile[] }>> {
  return {
    queryKey: ['cosmoClient', cosmoClient.credentials, 'searchUsers', nickname],
    queryFn: () =>
      cosmoClient.credentials
        ? cosmoClient.searchUsers(nickname)
        : proxyClient.searchUsers(nickname),
    staleTime: Infinity,
  };
}

async function getPaginatedObjekts(
  getPage: (
    startAfter?: number,
  ) => Promise<Paginated<{ total: number; objekts: Objekt[] }>>,
  startAfter?: number,
) {
  let objekts: Objekt[] = [];
  let page = await getPage(startAfter);
  objekts.push(...page.objekts);
  if (page.hasNext) {
    let nextStartAfter = parseInt(page.nextStartAfter);
    let promises: Promise<Objekt[]>[] = [];
    for (
      let startAfter = nextStartAfter;
      startAfter < page.total;
      startAfter += nextStartAfter
    ) {
      promises.push(getPage(startAfter).then(page => page.objekts));
    }
    objekts.push(...(await Promise.all(promises)).flat());
  }
  return objekts;
}

export function allOwnedObjektsQuery(
  address: string,
): FetchQueryOptions<Objekt[]> {
  return {
    queryKey: ['allOwnedObjekts', address],
    queryFn: () =>
      getPaginatedObjekts((startAfter?: number) =>
        queryClient.fetchQuery(ownedObjektsQuery(address, startAfter)),
      ),
    staleTime: Infinity,
  };
}

export function ownedObjektsQuery(
  address: string,
  startAfter: number = 0,
): FetchQueryOptions<Paginated<{ total: number; objekts: Objekt[] }>> {
  return {
    queryKey: ['ownedObjekts', address, startAfter],
    queryFn: () => cosmoClient.getOwnedObjekts(address, startAfter),
    staleTime: Infinity,
  };
}

function userDetailsQuery(address: string): FetchQueryOptions<{
  transfers: (Transfer & { objekt: Objekt })[];
  lists: List[];
}> {
  return {
    queryKey: ['userDetails', address],
    queryFn: () => polarisClient.getUserDetails(address),
    staleTime: Infinity,
  };
}

export function userTransfersQuery(
  address: string,
): FetchQueryOptions<(Transfer & { objekt: Objekt | {} })[]> {
  return {
    queryKey: ['userDetails', address, 'transfers'],
    queryFn: () =>
      queryClient
        .fetchQuery(userDetailsQuery(address))
        .then(details => details.transfers),
    staleTime: Infinity,
  };
}

export function userWantsListQuery(
  address: string,
): FetchQueryOptions<string[]> {
  return {
    queryKey: ['userDetails', address, 'wantsList'],
    queryFn: () =>
      queryClient
        .fetchQuery(userDetailsQuery(address))
        .then(
          details =>
            details.lists.find(list => list.name === 'want')?.collectionIds ||
            [],
        ),
    staleTime: Infinity,
  };
}

export async function getNicknames(
  addresses: string[],
): Promise<{ [address: string]: string }> {
  if (addresses.length == 0) {
    return {};
  }
  let promises: Promise<[string, string][]>[] = [];
  for (let i = 0; i < addresses.length; i += 150) {
    let addressesBatch = addresses.slice(i, i + 150);
    promises.push(
      (async () => {
        let response = await fetch(
          `https://search.goranmoomin.dev/user/v1/by-address/${addressesBatch.join(
            ',',
          )}`,
        );
        let json = (await response.json()) as {
          nickname: string;
          address: string;
          profileImageUrl: string;
        }[];
        return json.map(({ address, nickname }) => [address, nickname]);
      })(),
    );
  }
  let entries = (await Promise.all(promises)).flat();
  return Object.fromEntries(entries);
}

export function nicknameQuery(address: string): FetchQueryOptions<string> {
  return {
    queryKey: ['nicknames', address],
    queryFn: async () => {
      let nicknames = await getNicknames([address]);
      return nicknames[address] || address;
    },
    staleTime: Infinity,
  };
}

export function batchNicknamesQuery(addresses: string[]): FetchQueryOptions<{
  [address: string]: string;
}> {
  return {
    queryKey: ['batchNicknames', addresses],
    queryFn: async () => {
      let unknownAddresses = addresses.filter(
        address => !queryClient.getQueryData(['nicknames', address]),
      );

      if (unknownAddresses.length === 0) {
        // Return known nicknames from cache
        return Object.fromEntries(
          addresses.map(address => [
            address,
            (queryClient.getQueryData(['nicknames', address]) as string) ||
              address,
          ]),
        );
      }

      let newNicknames = await getNicknames(unknownAddresses);

      for (let [address, nickname] of Object.entries(newNicknames)) {
        queryClient.setQueryData(['nicknames', address], nickname);
      }

      return Object.fromEntries(
        addresses.map(address => [
          address,
          newNicknames[address] ||
            (queryClient.getQueryData(['nicknames', address]) as string) ||
            address,
        ]),
      );
    },
    staleTime: Infinity,
  };
}

export function gravitiesQuery(
  artist: 'tripleS' | 'artms' = 'tripleS',
): FetchQueryOptions<Gravities> {
  return {
    queryKey: ['gravities', artist],
    queryFn: () => cosmoClient.getGravities(artist),
    staleTime: Infinity,
  };
}

export function gravityQuery(
  artist: 'tripleS' | 'artms',
  gravityId: number,
): FetchQueryOptions<Gravity> {
  return {
    queryKey: ['gravity', artist, gravityId],
    queryFn: () => cosmoClient.getGravity(artist, gravityId),
    staleTime: Infinity,
  };
}

export function pollDetailQuery(
  artist: 'tripleS' | 'artms',
  gravityId: number,
  pollId: number,
): FetchQueryOptions<PollDetail> {
  return {
    queryKey: ['pollDetail', artist, gravityId, pollId],
    queryFn: () =>
      cosmoClient.credentials
        ? cosmoClient.getPollDetail(artist, gravityId, pollId)
        : proxyClient.getPollDetail(artist, gravityId, pollId),
    staleTime: Infinity,
  };
}
