import {
  useState,
  useRef,
  useLayoutEffect,
  useMemo,
  Fragment,
  Dispatch,
} from 'react';

import {
  Button,
  Card,
  Checkbox,
  Chip,
  Divider,
  Dropdown,
  DropdownTrigger,
  DropdownMenu,
  DropdownItem,
  Input,
  Modal,
  ModalContent,
  Popover,
  PopoverContent,
  PopoverTrigger,
  Select,
  SelectItem,
  Selection,
  useDisclosure,
} from '@nextui-org/react';
import { CloseFilledIcon } from '@nextui-org/shared-icons';

import { useWindowVirtualizer } from '@tanstack/react-virtual';
import { useHotkeys } from 'react-hotkeys-hook';

import { type Objekt } from '../cosmo';
import { SearchIcon, DotsHorizontalRoundedIcon } from '../icons';
import { polarisClient } from '../polaris';
import { getShortCollectionId } from '../utils';

import { useSelectedObjekts, useSendObjektsActions } from '../stores/objekts';

function Objekt({
  objekt,
  ownedObjekts,
  wantsList,
  onObjektPress,
  onListChange,
}: {
  objekt: Objekt;
  ownedObjekts: Objekt[];
  wantsList: string[];
  onObjektPress?: (objekt: Objekt) => void;
  onListChange?: () => void;
}) {
  let shortCollectionId = getShortCollectionId(objekt);
  let ownedTransferableObjekts = ownedObjekts.filter(
    objekt => objekt.transferable,
  );
  let selectedObjekts = useSelectedObjekts();
  let { setSelectedObjekts } = useSendObjektsActions();
  let isSomeObjektSelected = ownedTransferableObjekts.some(objekt =>
    selectedObjekts.includes(objekt),
  );
  let isEveryObjektSelected = ownedTransferableObjekts.every(objekt =>
    selectedObjekts.includes(objekt),
  );

  function onSelectedObjektsToggle() {
    if (isEveryObjektSelected) {
      setSelectedObjekts(
        selectedObjekts.filter(
          objekt => !ownedTransferableObjekts.includes(objekt),
        ),
      );
    } else {
      setSelectedObjekts([
        ...selectedObjekts,
        ...ownedTransferableObjekts.filter(
          objekt => !selectedObjekts.includes(objekt),
        ),
      ]);
    }
  }

  return (
    <div className='flex flex-col items-center gap-2'>
      <div className='relative w-full aspect-w-[1083] aspect-h-[1673]'>
        <div className='group absolute inset-0'>
          <Card
            className='absolute inset-0'
            shadow='none'
            radius='sm'
            isPressable={!!onObjektPress}
            onPress={() => onObjektPress?.(objekt)}
          >
            <img src={objekt.frontImage} />
          </Card>
          {ownedTransferableObjekts.length ? (
            <Checkbox
              className='absolute w-9 h-9 top-2 left-2 z-10'
              classNames={{ wrapper: 'bg-background' }}
              isSelected={isEveryObjektSelected && isSomeObjektSelected}
              isIndeterminate={!isEveryObjektSelected && isSomeObjektSelected}
              onValueChange={onSelectedObjektsToggle}
            />
          ) : null}
          <Dropdown placement='bottom-start'>
            <DropdownTrigger>
              <Button
                isIconOnly
                radius='sm'
                className='absolute invisible group-hover:visible min-w-[auto] w-7 h-7 top-2 right-2 opacity-90'
              >
                <DotsHorizontalRoundedIcon className='w-5 h-5 text-foreground-500' />
              </Button>
            </DropdownTrigger>
            <DropdownMenu
              onAction={async key => {
                if (!polarisClient.token) {
                  alert('WIP: Handle gracefully, show relogin message.');
                  return;
                }
                switch (key) {
                  case 'add-wants':
                    await polarisClient.addCollectionsToList('want', [
                      objekt.collectionId,
                    ]);
                    onListChange?.();
                    break;
                  case 'add-haves':
                    await polarisClient.addCollectionsToList('have', [
                      objekt.collectionId,
                    ]);
                    onListChange?.();
                    break;
                  case 'remove-wants':
                    await polarisClient.removeCollectionsFromList('want', [
                      objekt.collectionId,
                    ]);
                    onListChange?.();
                    break;
                  case 'remove-haves':
                    await polarisClient.removeCollectionsFromList('have', [
                      objekt.collectionId,
                    ]);
                    onListChange?.();
                    break;
                }
              }}
            >
              {wantsList.includes(objekt.collectionId) ? (
                <DropdownItem key='remove-wants'>
                  Remove from My Wants
                </DropdownItem>
              ) : (
                <DropdownItem key='add-wants'>Add to My Wants</DropdownItem>
              )}
            </DropdownMenu>
          </Dropdown>
        </div>
      </div>
      <div>
        <Popover placement='bottom'>
          <PopoverTrigger>
            <Chip
              className='text-tiny'
              size='sm'
              variant='flat'
              startContent={
                ownedObjekts.length > 0 ? (
                  <span className='w-2 h-2 mx-1 rounded-full bg-default-400' />
                ) : null
              }
            >
              {shortCollectionId}
            </Chip>
          </PopoverTrigger>
          <PopoverContent className='p-0 flex flex-col gap-2 py-2'>
            <div className='px-2 text-small font-bold'>Objekts</div>
            {ownedObjekts.map(objekt => (
              <Fragment key={objekt.tokenId}>
                <Divider />
                <Checkbox
                  size='sm'
                  className='px-4'
                  isSelected={selectedObjekts.includes(objekt)}
                  isDisabled={!objekt.transferable}
                  onValueChange={isSelected => {
                    if (isSelected) {
                      setSelectedObjekts([...selectedObjekts, objekt]);
                    } else {
                      setSelectedObjekts(
                        selectedObjekts.filter(
                          selectedObjekt => selectedObjekt !== objekt,
                        ),
                      );
                    }
                  }}
                >
                  {shortCollectionId} #{objekt.objektNo}
                </Checkbox>
              </Fragment>
            ))}
            {ownedObjekts.length == 0 && (
              <>
                <Divider />
                <div className='px-2'>You do not own this objekt.</div>
              </>
            )}
          </PopoverContent>
        </Popover>
      </div>
    </div>
  );
}

export function ObjektFilters({
  artist,
  members,
  seasons,
  classes,
  artistOptions,
  memberOptions,
  seasonOptions,
  classOptions,
  setArtist,
  setMembers,
  setSeasons,
  setClasses,
  query,
  setQuery,
}: {
  artist: string;
  members: string[];
  seasons: string[];
  classes: string[];
  artistOptions: string[];
  memberOptions: string[];
  seasonOptions: string[];
  classOptions: string[];
  setArtist: Dispatch<string>;
  setMembers: Dispatch<string[]>;
  setSeasons: Dispatch<string[]>;
  setClasses: Dispatch<string[]>;
  query: string;
  setQuery: Dispatch<string>;
}) {
  let {
    isOpen: isSearchOpen,
    onOpen: onSearchOpen,
    onOpenChange: onSearchOpenChange,
  } = useDisclosure();
  useHotkeys('mod+f', onSearchOpen, { preventDefault: true });

  function onArtistChange(artists: Selection) {
    setArtist(Array.from(artists as Set<string>)[0]);
  }
  function onMembersChange(members: Selection) {
    setMembers(Array.from(members as Set<string>));
  }
  function onSeasonsChange(seasons: Selection) {
    setSeasons(Array.from(seasons as Set<string>));
  }
  function onClassesChange(classes: Selection) {
    setClasses(Array.from(classes as Set<string>));
  }

  return (
    <>
      <div className='flex flex-col sm:flex-row w-full gap-4'>
        <Select
          label='Artist'
          placeholder='Select an artist'
          selectedKeys={[artist]}
          onSelectionChange={onArtistChange}
          className='flex-1'
        >
          {artistOptions.map(artist => (
            <SelectItem key={artist} value={artist}>
              {artist}
            </SelectItem>
          ))}
        </Select>
        <Select
          label='Member'
          placeholder='Select a member'
          selectionMode='multiple'
          selectedKeys={members}
          onSelectionChange={onMembersChange}
          className='flex-1'
          endContent={
            <span
              role='button'
              tabIndex={0}
              data-filled={members.length > 0}
              className='p-2 -m-2 z-10 hidden absolute right-8 top-5 appearance-none select-none opacity-0 hover:!opacity-100 cursor-pointer active:!opacity-70 rounded-full outline-none text-large data-[filled=true]:block data-[filled=true]:opacity-70 transition-opacity motion-reduce:transition-none'
              onClick={() => setMembers([])}
            >
              <CloseFilledIcon />
            </span>
          }
        >
          {memberOptions.map(member => (
            <SelectItem key={member} value={member}>
              {member}
            </SelectItem>
          ))}
        </Select>
        <Select
          label='Season'
          placeholder='Select a season'
          selectionMode='multiple'
          selectedKeys={seasons}
          onSelectionChange={onSeasonsChange}
          className='flex-1'
          endContent={
            <span
              role='button'
              tabIndex={0}
              data-filled={seasons.length > 0}
              className='p-2 -m-2 z-10 hidden absolute right-8 top-5 appearance-none select-none opacity-0 hover:!opacity-100 cursor-pointer active:!opacity-70 rounded-full outline-none text-large data-[filled=true]:block data-[filled=true]:opacity-70 transition-opacity motion-reduce:transition-none'
              onClick={() => setSeasons([])}
            >
              <CloseFilledIcon />
            </span>
          }
        >
          {seasonOptions.map(season => (
            <SelectItem key={season} value={season}>
              {season}
            </SelectItem>
          ))}
        </Select>
        <Select
          label='Class'
          placeholder='Select a class'
          selectionMode='multiple'
          selectedKeys={classes}
          onSelectionChange={onClassesChange}
          className='flex-1'
          endContent={
            <span
              role='button'
              tabIndex={0}
              data-filled={classes.length > 0}
              className='p-2 -m-2 z-10 hidden absolute right-8 top-5 appearance-none select-none opacity-0 hover:!opacity-100 cursor-pointer active:!opacity-70 rounded-full outline-none text-large data-[filled=true]:block data-[filled=true]:opacity-70 transition-opacity motion-reduce:transition-none'
              onClick={() => setClasses([])}
            >
              <CloseFilledIcon />
            </span>
          }
        >
          {classOptions.map(class_ => (
            <SelectItem key={class_} value={class_}>
              {class_}
            </SelectItem>
          ))}
        </Select>
        <div className='flex-1'>
          <Button
            className='bg-default-100 gap-1 h-14 w-full text-foreground-500 font-medium'
            onPress={onSearchOpen}
            startContent={<SearchIcon className='w-4 h-4' />}
          >
            {query || 'Search'}
          </Button>
        </div>
      </div>
      <Modal
        isOpen={isSearchOpen}
        onOpenChange={onSearchOpenChange}
        placement='top'
        shouldBlockScroll={false}
      >
        <ModalContent>
          {() => (
            <Input
              isClearable
              label='Search'
              placeholder='Search Objekts'
              value={query}
              onValueChange={setQuery}
              classNames={{ input: 'text-base placeholder:text-small' }}
              autoFocus
              startContent={
                <SearchIcon className='w-5 h-5 text-foreground-500' />
              }
            />
          )}
        </ModalContent>
      </Modal>
    </>
  );
}

export function ObjektList({
  objekts,
  ownedObjekts,
  wantsList,
  onObjektPress,
  onListChange,
}: {
  objekts: Objekt[];
  ownedObjekts: Objekt[];
  wantsList: string[];
  onObjektPress?: (objekt: Objekt) => void;
  onListChange?: () => void;
}) {
  let ownedObjektMap = useMemo(() => {
    let map: Map<string, Objekt[]> = new Map();
    for (let objekt of ownedObjekts) {
      if (!map.has(objekt.collectionId)) {
        map.set(objekt.collectionId, []);
      }
      map.get(objekt.collectionId)!.push(objekt);
    }
    for (let objekts of map.values()) {
      objekts.sort((a, b) => a.objektNo - b.objektNo);
    }
    return map;
  }, [ownedObjekts]);

  let [objektsPerRow, setObjektsPerRow] = useState(2);

  useLayoutEffect(() => {
    let smQuery = window.matchMedia('(min-width: 640px)');
    let lgQuery = window.matchMedia('(min-width: 1024px)');

    function updateObjektsPerRow() {
      if (lgQuery.matches) {
        setObjektsPerRow(6);
      } else if (smQuery.matches) {
        setObjektsPerRow(4);
      } else {
        setObjektsPerRow(3);
      }
    }

    updateObjektsPerRow();
    smQuery.addEventListener('change', updateObjektsPerRow);
    lgQuery.addEventListener('change', updateObjektsPerRow);

    return () => {
      smQuery.removeEventListener('change', updateObjektsPerRow);
      lgQuery.removeEventListener('change', updateObjektsPerRow);
    };
  }, []);

  let rows = useMemo(() => {
    let rows: Objekt[][] = [];
    for (let i = 0; i < objekts.length; i += objektsPerRow) {
      rows.push(objekts.slice(i, i + objektsPerRow));
    }
    return rows;
  }, [objekts, objektsPerRow]);

  let parentRef = useRef<HTMLDivElement>(null);
  let parentOffsetRef = useRef(0);
  useLayoutEffect(() => {
    parentOffsetRef.current = parentRef.current?.offsetTop ?? 0;
  }, []);

  let virtualizer = useWindowVirtualizer({
    count: rows.length,
    estimateSize: () => {
      return objektsPerRow === 6 ? 320 : objektsPerRow === 4 ? 450 : 540;
    },
    scrollMargin: parentOffsetRef.current,
  });
  let virtualItems = virtualizer.getVirtualItems();

  return (
    <div ref={parentRef}>
      <div style={{ height: virtualizer.getTotalSize() }} className='relative'>
        <div
          className='absolute top-0 inset-x-0'
          style={{
            transform: `translateY(${
              (virtualItems[0]?.start ?? 0) - virtualizer.options.scrollMargin
            }px)`,
          }}
        >
          <div className='flex flex-col gap-4'>
            {virtualItems.map(item => {
              let rowObjekts = rows[item.index];
              return (
                <div
                  key={item.index}
                  data-index={item.index}
                  ref={virtualizer.measureElement}
                >
                  <div
                    className={
                      objektsPerRow === 3
                        ? 'grid grid-cols-3 gap-4'
                        : objektsPerRow === 4
                          ? 'grid grid-cols-4 gap-4'
                          : 'grid grid-cols-6 gap-4'
                    }
                  >
                    {rowObjekts.map(objekt => (
                      <Objekt
                        key={objekt.collectionId}
                        objekt={objekt}
                        ownedObjekts={
                          ownedObjektMap.get(objekt.collectionId) ?? []
                        }
                        wantsList={wantsList}
                        onObjektPress={onObjektPress}
                        onListChange={onListChange}
                      />
                    ))}
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
}
